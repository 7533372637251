.HomePageContainer {
  width: 100%;
  padding: 25px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.SessionImage {
  margin-top: -10px;
  height: 400px;
  width: 600px;
  transform: perspective(900px) rotateY(-20deg);
}

.tab-outer-div {
  border-bottom: 1;
  border-color: divider;
  display: flex;
  flex-direction: column;
}

.tab-cont {
  width: 85vw;
}

.tab-list {
  width: 100%;
  display: flex;
  height: 5vh;
  align-items: center;
  overflow-y: hidden;
}

.tab {
  background: none;
  border: none;
  margin-left: 1rem;
  margin-right: 1rem;
  height: 100%;
  font-size: 1rem;
  cursor: pointer;
}

.tab:hover {
  border-bottom: solid;
}

.tab:focus {
  border-bottom: solid;
}

.search-bar-cont {
  width: 25%;
  display: flex;
  /* justify-content: right; */
  background-color: antiquewhite;
}

.search-bar {
  width: 100%;
}