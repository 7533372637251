.carousel-container {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.carousel-wrapper {
    display: flex;
    width: 100%;
    position: relative;
}

.carousel-content-wrapper {
    overflow: hidden;
    width: 100%;
    height: 100%;
}

.carousel-content {
    display: flex;
    transition: all 250ms linear;
    -ms-overflow-style: none;

    scrollbar-width: none;

}

/* hide scrollbar in webkit browser */
.carousel-content::-webkit-scrollbar,
.carousel-content::-webkit-scrollbar {
    display: none;
}

.left-arrow,
.right-arrow {
    position: absolute;
    z-index: 1;
    top: 50%;
    transform: translateY(-50%);
    width: 48px;
    height: 48px;
    border-radius: 24px;
    background-color: white;
    border: 1px solid #ddd;
}

.left-arrow {
    left: 24px;
}

.right-arrow {
    right: 24px;
}

.carousel-content>* {
    width: 100%;
    flex-shrink: 0;
    flex-grow: 1;
}

.ccardbox {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.ccard {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    height: 300
}

.outer-card-cont {
    width: 85vw;
    height: 50vh;
    display: flex;
    /* justify-content: center;
    align-items: center; */
    flex-wrap: wrap;
    /* border-top: black 1px solid; */
    /* border-bottom: black 1px solid; */
    overflow-y: scroll;
}