.assignmentTray {
    display: flex;
    flex-direction: column;
    margin-top: 20px;
}

.assignment-header {
    background-color: aliceblue;
    padding: 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.assignment-header-name {
    margin-left: 0;
}

.assignment-header-dueDate {
    margin-right: 0;
}

.assignment-body {
    display: flex;
}