.classroom {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.classroom-header {
    width: 80%;
    height: 200px;
    border-radius: 10px;
    background-color: antiquewhite;
    margin-top: 50px;
    background-image: url("../../assets/photos/50.jpg");
    object-fit: cover;
}

.classroom-brand {
    min-width: 80%;
    margin-top: 20px;
}

.classroom-body {
    min-width: 80%;
    margin-top: 40px;
}