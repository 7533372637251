.BoardAreaHeader {
  width: 100%;
  display: flex;
  justify-content: center;
  padding-top: 40px;
  padding-bottom: 40px;
}

.react-kanban-column {
  min-height: 70vh !important;
}

.react-kanban-board {
  justify-content: center;
  width: 100%;
  height: 100%;
}

.name {}