.App {
  background-color: white;
  height: 100%;
  
}

/* #root {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
} */